<template>
  <div>
    <div class='header'>
      <div class='header__content'>
        <a :href='dashboards_path' class='header__logo'>
          <Hudulogo v-if='!logo_url' class='hudu'></Hudulogo>
          <img :src='logo_url' v-if='logo_url' style='max-width: 100px;max-height: 40px;'>
        </a>
        <slot name='logo' @click.prevent.stop='switchSubMenu'></slot>
        <nav class='mobile-nav'>
          <a href='#' @click.prevent.stop='toggleMenu' class='header__a header__button'><bar-icon></bar-icon></a>
        </nav>
        <div class='header__nav'>
          <a :href='companies_path' :class='{"header__a header__a__tooltip": true, "active": active == 2}'>
            <h2 class='header__a-big'>{{structure_name}}</h2>
          </a>
          <a href='#'  v-if='!cant_access_global_kb' :class='{"header__a header__a__tooltip header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'>
            <h2 class='header__a-big'>KB</h2>
          </a>
         
          <a href='#' v-if='!cant_access_personal_vault' :class='{"header__a header__a__tooltip header__a--vault": true, "active": active == 8}' :href='vault_path'>
            <h2 class='header__a-big'>My Vault</h2>
          </a>
          <a v-if='is_admin' :class='{"header__a header__a__tooltip header__a--admin": true, "active": active == 7}' :href='admin_path'>
            <h2 class='header__a-big'>Admin</h2>
          </a>
          <a v-if='is_editor && !is_admin' :class='{"header__a header__a__tooltip header__a--admin": true, "active": active == 7}' :href='admin_path'>
            <h2 class='header__a-big'>Tools</h2>
          </a>
        </div>
        <div class='header__search'>
          <icon></icon>
          <input type='search'
            autocomplete="off" id='mainSearchInput' ref="searchInput" placeholder="Search" @input='changeSearch'>
          <span v-show='!showSearcher'>ctrl + /</span>
        </div>
        <div class='header__nav'>
          
          <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__a__tooltip header__a--resync': true, 'header__a': true, 'active': showSyncBar }" href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M369.1 142.9L312 200l24 24H496V64L472 40 414.4 97.6l0 0c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0l-45.3-45.3c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0l0 0z"/></svg>
          </a>
          <a @click.prevent.stop="switchOnboarder" :class='{"header__a header__a__tooltip header__a--learn": true, "active": active == 9}' href='#'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 96c-17.7 0-32 14.3-32 32v32H0V128C0 75 43 32 96 32h97c70.1 0 127 56.9 127 127c0 52.4-32.2 99.4-81 118.4l-63 24.5V320v32H112V320 280 258.1l20.4-7.9 83.4-32.4C240 208.3 256 185 256 159c0-34.8-28.2-63-63-63H96zm80 320v64H112V416h64z"/></svg>
          </a>
        </div>
        
        <a href='#' @click.prevent.stop="switchDropper" class='profile'>
          <img :src='image' width='35' height='35'><h3 class='profile__name'>{{short_name}}</h3>
        </a>
      </div>
    </div>
    <div class='drop-header' v-if='showDrop'>
      <a
            :class='{"header__a header__a--home": true, "active": active == 1}' :href='dashboards_path'><i class='fal fa-home'></i></a>
      <a
      :class='{"header__a header__a--companies": true, "active": active == 2 }'
      :href='companies_path'><i class='fal fa-store-alt'></i></a>
      <a  v-if='!cant_access_global_kb' :class='{" header__a header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'><i class='fal fa-book-reader'></i></a>
      <a :href='vault_path' v-if='!cant_access_personal_vault' :class="{'header__a': true, 'active': active == 8 }"><i class="fal fa-user-lock" style='margin: 0'></i></a>
      <a v-if='is_admin' :class='{"header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fal fa-cog'></i></a>
      <a v-if='is_editor && !is_admin' :class='{"header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fal fa-tools'></i></a>
      <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__a': true, 'active': showSyncBar }" href='#'><i class="fal fa-sync" style='margin: 0'></i></a>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Icon from "../components/icon.vue";
import DownArrow from "../components/DownArrow.vue";
import VTooltip from 'v-tooltip';
import Hudulogo from "../components/Hudulogo.vue";
import CompaniesIcon from "../components/icons/CompaniesIcon.vue";
import ArticlesIcon from "../components/icons/ArticlesIcon.vue";
import VaultIcon from "../components/icons/VaultIcon.vue";
import AdminIcon from "../components/icons/AdminIcon.vue";
import ResyncIcon from "../components/icons/ResyncIcon.vue";
import BarIcon from "../components/icons/BarIcon.vue";
export default {
  components: {
    Icon,
    BarIcon,
    CompaniesIcon,
    ArticlesIcon,
    VaultIcon,
    AdminIcon,
    ResyncIcon,
    DownArrow,
    Hudulogo
  },
  directives: {
    'tooltip': VTooltip
  },
  props: [
    "name", 
    "image", 
    "count",
    "tab", 
    "logo_url",
    "show_menu",
    "user_role",
    "show_sync_bar",
    "account_name",
    "user_name",
    "structure_name",
    "dashboards_path",
    "companies_path",
    "process_templates_path",
    "articles_path",
    "admin_path",
    "vault_path",
    "short_name",
    "is_admin",
    "is_editor",
    "billing_successful",
    "cant_access_personal_vault",
    "cant_access_global_kb"
    ],
  computed: {
    ...mapGetters("globals", {
      'showVault': 'showVault',
      'showSyncBar': 'showSyncBar',
      'showDropper': 'showDropper',
      'showSearcher': 'showSearcher',
      'showSubMenu': 'showSubMenu',
      'showOnboarder': 'showOnboarder',
    }),
    active () {
      if (this.showVault || this.showSyncBar || this.showSubMenu) {
        return 0
      } else if (this.tab == "dashboard") {
        return 1
      } else if (this.tab == "companies") {
        return 2
      } else if (this.tab == "articles") {
        return 3
      } else if (this.tab == "process_templates") {
        return 4
      } else if (this.tab == "admin") {
        return 7
      } else if (this.tab == "vault") {
        return 8
      } else if (this.tab == "onboarder") {
        return 9
      }
    }
  },
  methods: {
    toggleMenu () {

      this.showDrop = !this.showDrop;

      var companySidebar = document.querySelector(".company-sidebar");

      if (companySidebar.style.display === "none" || companySidebar.style.display === "" ) {
        companySidebar.style.display = "block";
      } else {
        companySidebar.style.display = "none";
      }

    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    changeSearch(e) {
      this.$store.commit('globals/changeSearchQuery', e.target.value)
    },
    switchVault () {
      if (this.showVault) {
        this.toggle(0)
      } else {
        this.toggle(4)
        this.$nextTick(() => document.querySelector("#vaultSearchInput").focus())
      }
    },
    switchSubMenu () {
      if (this.showSubMenu) {
        this.toggle(0)
      } else {
        this.toggle(6)
      }
    },
    switchOnboarder () {
      if (this.showSubMenu) {
        this.toggle(0)
      } else {
        this.toggle(7)
      }
    },
    switchSearch () {
      if (this.showSearcher) {
        this.toggle(0)
      } else {
        this.toggle(2)
        this.$nextTick(() => document.querySelector("#mainSearchInput").focus())
      }
    },
    switchDropper () {
      if (this.showDropper) {
        this.toggle(0)
      } else {
        this.toggle(3)
      }
    },
    switchSyncBar () {
      if (this.showSyncBar) {
        this.toggle(0)
      } else {
        this.toggle(5)
      }
    },
    toggleDrop () {

    }
  },
  data () {
    return {
      isSubMenuShown: false,
      isShown: false,
      showDrop: false,
      structure_name_msg: "test"
    }
  }
}
</script>

